import React, { Component } from "react"
import { Section, QuickInfoWrapper } from "../../utils"

export default class SleepyKnightInfo extends Component {
  render() {
    return (
      <Section style={{ width: "100%" }}>
        <QuickInfoWrapper>
          <p className="text">
            <u>Description:</u>
            <br />
            Simple platformer about a knight that is sleeping all day and…night.
            <br />
            <br />
            Created by eddynardo.
            <br />
            Graphics by 0×72 and Adam Saltsman.
            <br />
            Theme song “Weeklybeats 2014 #5 -I’ve Got Nothing !” by RoccoW
            licensed under an Attribution-ShareAlike License.
            <br />
            <br />
          </p>
          <p className="controllerText">
            <u>Instructions:</u>
            <br />
            Use left and right mouse buttons or Z and X on keyboard.
          </p>
        </QuickInfoWrapper>
      </Section>
    )
  }
}
